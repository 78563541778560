<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="font-weight-bold mb-3">ชื่อที่แสดงใน line : {{name}}</h3>
      </v-col>
    </v-row>  
    <v-row    v-if="show_emp_code" class="text-center">
      <v-col class="mb-4">
            <v-text-field
            v-model="per_id"
            :counter="8"
            label="รหัสพนักงาน"
            required
        ></v-text-field>
      </v-col>  
    </v-row>  
    <v-row    v-if="show_emp_code" class="text-center">
      <v-col class="mb-4">
            <v-text-field
            v-model="birth_id"
            :counter="8"
            label="ว/ด/ปเกิด เช่น 1 มกราคม 2541 ใส่ 01012541"
            required
        ></v-text-field>
      </v-col>  
    </v-row>  
    <center>
      <v-btn v-if="show_otp" large color="success" @click="sendotp">ลงทะเบียน</v-btn>
    </center>  
    <v-row   v-if="show_btn" class="text-center">
      <v-col class="mb-4">
            <v-text-field
            v-model="otp"
            :counter="6"
            label="OTP"
            required
        ></v-text-field>
      </v-col>  
    </v-row>      
    
    <center  v-if="show_btn" >
      <v-btn class="mr-10" large color="success" @click="post_register">ยืนยัน OTP</v-btn>
      <v-btn large color="error" @click="logout">ยกเลิก</v-btn>
    </center>        


  </v-container>
</template>

<script>

import liff from "@line/liff";
import axios from "axios";

export default {
  data () {
    return {
      name: '',
      per_id:'',
      birth_id : '',
      otp : '',
      show_emp_code : 1 ,
      show_otp : 1 ,
      show_btn : 0 ,
      imgURL: '',
      uid :'',
      items: null,
      app_link:'',
    }
  },
  created() {
    let uri = window.location.search.substring(); 
    let params = new URLSearchParams(uri);
    this.app_link = params.get("app");
    this.initializeLiff();
  },
  methods: {
    initializeLiff: function () {

      liff.init({
    liffId: process.env.VUE_APP_LIFF_ID, // Use own liffId
    })
    .then(() => {
        // start to use LIFF's api
        if (liff.isLoggedIn()) {

            liff.getProfile()
            .then(profile => {

              this.name =  profile.displayName 
              this.uid = profile.userId

              this.check_registed()

              console.log(" app => " ,this.app_link)

            })
            .catch((err) => {
              console.log('error', err)
            })            
            

            //me.$router.push({ name: 'login' })
          }else{

            liff.login();

          }
    })
    .catch((err) => {
        console.log(err);
    });

    },

    logout: function () {

      if (liff.isLoggedIn()) {
        liff.logout()
        this.$router.push({ name: 'home' })
      }
    },
    register: function () {
 
    },
    sendotp() {
 
      this.check_user()

    },

      check_user(){

            axios.get(process.env.VUE_APP_WS+"get_user_sendmail.php",{
                        params: {
                            suid: this.uid ,
                            scode: this.per_id,
                            spass: this.birth_id
                            
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))

                console.log( "check_user data = " + result.data )

                if ( result.data == "mailsend" ){
                    this.show_btn = 1
                    this.show_otp = 0
                    alert(" ได้ส่ง OTP ไปแล้ว กรุณาเช็คเมลล์ ")
                }
                if ( result.data == "mailnotsend" ){
                    alert(" ไม่สามารถส่ง email ได้ กรุณาติดต่อ Admin ")
                }
                if ( result.data == "notfound" ){
                    alert(" ไม่เจอรหัสพนักงาน กรุณาติดต่อ Admin ")
                }                
                if ( result.data == "success" ){
                    alert(" ลงทะเบียนเสร็จเรียบร้อยแล้ว ")
                }     
                if ( result.data == "notsuccess" ){
                    alert(" ข้อมูลผิดพลาด ลงทะเบียนเ ไม่สำเร็จ ")
                }             
            })
      },

      check_registed(){

            var v_app_link = this.app_link

            axios.get(process.env.VUE_APP_WS+"get_user_line_id.php",{
                        params: {
                            scode: this.uid
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))
                console.log(this.items ,  this.uid )
                console.log( "check_registed data = " + this.items[0].emp_name  )

                if ( this.items[0].emp_name != null ){

                  this.show_otp = 0
                  this.show_emp_code = 0
                  
                  //console.log( "appLink is " + v_app_link + " =>" + v_app_link.substring(0, 3))

                  if ( v_app_link == "eslip" ){

                    console.log( "https://docu.ornategroup.com?line_id="+this.uid) 
                    
                    //window.location.href = "https://docu.ornategroup.com?line_id="+this.uid

                    window.open("https://docu.ornategroup.com?line_id=" + this.uid, "_blank");

                  }                  

                  if ( v_app_link == "leave" ){

                    console.log( "https://ornwebapp.com/Leave/login.php?ornid="+this.items[0].orn_id) 
                    
                    //window.location.href = "https://ornwebapp.com/Leave/login.php?ornid="+this.items[0].orn_id

                    window.open("https://ornwebapp.com/Leave/login.php?ornid="+this.items[0].orn_id, "_blank");

                  }                     
                  
                  if ( v_app_link == "repair" ){

                    console.log( "https://ornwebapp.com/ornrepair/login.php?ornid="+this.items[0].orn_id) 
                    
                    //window.location.href = "https://ornwebapp.com/ornrepair/login.php?ornid="+this.items[0].orn_id

                    window.open("https://ornwebapp.com/ornrepair/login.php?ornid="+this.items[0].orn_id, "_blank");

                  }

                  if ( v_app_link == "refuel" ){

                  console.log( "http://oilbill.ornapp.online/?employee_id="+this.items[0].emp_code) 

                  //window.location.href = "http://oilbill.ornapp.online/?employee_id="+this.items[0].emp_code

                  window.open("https://ornwebapp.com/ornrepair/login.php?ornid="+this.items[0].orn_id, "_blank");

                  }    
                  
                  if ( v_app_link == "disc" ){

                    console.log( "https://emp.ornategroup.com/disc?ornid="+this.items[0].orn_id) 

                 //   window.location.href = "https://emp.ornategroup.com/disc?ornid="+this.items[0].orn_id

                  this.$store.dispatch('ac_setornid',this.items[0].orn_id)
                  this.$router.push('/disc')

                  }
                  
                  if ( v_app_link.substring(0, 3) == "emp" ){
                    //window.location.href = "https://ornwebapp.com//emp/"+v_app_link+".php?ornid="+this.items[0].orn_id

                    window.open("https://ornwebapp.com//emp/"+v_app_link+".php?ornid="+this.items[0].orn_id , "_blank");
                    
                  }

                  if ( v_app_link == null ){
                    alert(" รหัสพนักงานนี้ได้ลงทะเบียนเรียบร้อยแล้ว")
                  }
                
                }

            })
      },

      post_register(){

            axios.get(process.env.VUE_APP_WS+"post_update_lineid.php",{
                        params: {
                            suid: this.uid ,
                            scode: this.per_id ,
                            spassword: this.otp ,
                        }
                    }).then(result=>{
                this.items  = JSON.parse(JSON.stringify(result.data))
                console.log( "check_registed data = " + result.data )

                if ( result.data == "success" ){

                  alert(" รหัสพนักงานนี้ได้ลงทะเบียนเรียบร้อยแล้ว")
                  this.show_otp = 0
                  this.show_emp_code = 0
                  this.show_btn = 0
                }else{
                  alert(" ลงทะเบียนไม่สำเร็จ")
                }


            })
      },

  },
};
</script>
